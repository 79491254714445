.app-jumbotron-masthead {
  background-color: #003A5D;
  color: #FFFFFF;
}

.app-service-version-nav {
  margin-top: 40px;
}
.app-nav-list {
  margin-bottom: 0;
}

.app-service-index-item {
  margin-bottom: 40px;

  &:first-of-type {
    margin-top: 100px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.app-openapi-reference-section {
  margin-top: 40px;

  &:first-of-type {
    margin-top: 0;
  }

  header {
    margin-bottom: 20px;
  }
}

.app-openapi-reference-method {
  header {
    span.bsk-label {
      padding: 2px 4px;

      font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
      font-size: 90%;
    }
    code {
      word-break: break-word;
    }
  }
}

.app-openapi-reference-method-nav-item {
  font-family: Menlo,Monaco,Consolas,"Courier New",monospace;

  span.app-method-get {
    color: #379245;
  }

  code {
    background: none;
  }
}
